/* Used for the mobil menu */
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

body {
  font-size: 120%;
}

/* Row */
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/* Search / Search Results */
#search-container {
  margin-top: 23px;
}
#results-container {
  position: absolute;
  z-index: 9;
  background-color: aliceblue;
  padding-left: 0;
  list-style-type: none;
  margin: 0 0 0 80px;
}
#results-container li {
  padding: 10px;
}
#results-container li:hover {
  background-color: #dddddd;
}

/* Navigation */
.topnav {
  overflow: hidden;
  background-color: #FFF;
  border-bottom: #dddddd solid thin;
}
.topnav a {
  float: left;
  display: block;
  text-align: center;
  padding: 14px 16px ;
  text-decoration: none;
}
.topnav a:hover {
  background-color: #ddd;
  color: black;
}
.topnav .icon {
  display: none;
}

/* Today's Post */
.today {
  margin-top: 32px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 20px;
  //background-color: aliceblue;
  border: thin solid lightgray;
}

/* Post Listing */
.post-listing {
    list-style-type: none;
}
.post-listing blockquote {
  border: none;
  padding-top: 0;
}

/* Mobile post date and author */
.by-and-date-post-mobile {
  display: none;
  margin: 40px 0;
  font-size: smaller;
}

/* Mobile styles */
@media screen and (max-width: 600px) {

  /* On mobile we reset the margins on the Today's advice */
  .today {
    margin: 0;
    background-color: #FFFFFF;
    color: black;
    border: thin solid slateblue;
  }
  .today h3 { color: black; }
  /* Hide the date/author we use on desktop only */
  .by-and-date-post-page {
    display: none;
  }
  /* Show the mobile date/author block */
  .by-and-date-post-mobile {
    display: block;
  }
  /* Modify the search container to fit mobile */
  #search-container {
    width: 100%;
    padding-right: 30px;
  }
    #results-container {
      width: 330px;
      margin-left: 0;
    }
    #search-input { width: 100%; }

  /* Mobile navigation */
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}